import AmplifyObj, { Auth } from 'aws-amplify';
import { isEmpty } from 'lodash';

export const awsAuth = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_ID,

    // (required)- Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  },
};

export const configureCognito = (config) => {
  if (!isEmpty(config)) {
    AmplifyObj.configure(config);
  } else {
    AmplifyObj.configure(awsAuth);
  }
};

export default AmplifyObj;
