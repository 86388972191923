export const createReducer = (initialState, handlers) => (state = initialState, action) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }
  return state;
};

export const setData = (state, action) => {
  let { payload, id } = action;
  let x = {
    ...state,
    [id]: payload,
  };
  return x[id];
};
