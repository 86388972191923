export default {
  app_notification_message: 'APP_NOTIFICATION_MESSAGE',
  error_message_on_failure: 'ErrorMessageOnFailure',
  notification_data: 'NOTIFICATION_DATA',
  property_response_data: 'PROPERTY_RESPONSE_DATA',
  property_revision_data: 'PROPERTY_REVISION_DATA',
  error_log_data: 'ERROR_Log_DATA',
  property_data: 'PROPERTY_DATA',
  package_data: 'PACKAGE_DATA',
  property_export_data: 'PROPERTY_EXPORT_DATA',
  package_export_data: 'PACKAGE_EXPORT_DATA',
  invoice_package_export_data: 'INVOICE_PACKAGE_EXPORT_DATA',
  dashboard_data: 'DASHBOARD_DATA',
  jamfor_maklare_data: 'JAMFOR_MAKLARE_DATA',
  leads_invoice_data: 'LEADS_INVOICE_DATA',
  bevaka_data: 'BEVAKA_DATA',
  agent_data: 'AGENT_DATA',
  agent_property_data: 'AGENT_PROPERTY_DATA',
  bevaka_mail_logs_data: 'BEVAKA_MAIL_LOGS_DATA',
  is_loading: 'IsLoading',
  is_login: 'IsLogin',
  user_detail: 'UserDetail',
  agency_data: 'AGENCY_DATA',
  autocompleter_data: 'AUTOCOMPLETER_DATA',
  users_data: 'USERS_DATA',
  roles_data: 'ROLES_DATA',
  brand_data: 'BRAND_DATA',
  projects_data: 'PROJECTS_DATA',
  constructor_data: 'CONSTRUCTOR_DATA',
  article_data: 'ARTICLE_DATA',
  project_image_data: 'PROJECT_IMAGE_DATA',
  inquiry_data: 'INQUIRY_DATA',
  autocompleter_other_country_data: 'AUTOCOMPLETER_OTHER_COUNTRY_DATA',
  bevaka_stats_data: 'BEVAKA_STATS_DATA',
  by_brands_data: 'BY_BRANDS_DATA',
  by_agency_data: 'BY_AGENCY_DATA',
  by_agent_data: 'BY_AGENT_DATA',
  by_location_data: 'BY_LOCATION_DATA',
  brand_filter_data: 'BRAND_FILTER_DATA',
  agency_filter_data: 'AGENCY_FILTER_DATA',
  agent_filter_data: 'AGENT_FILTER_DATA',
  location_filter_data: 'LOCATION_FILTER_DATA',
  is_exporting: 'isExporting',
  prevRequest: 'PREVREQUEST',
  advertisement_data: 'ADVERTISEMENT_DATA',
  advertisement_log_data: 'ADVERTISEMENT_LOG_DATA',
  project_notification_data: 'PROJECT_NOTIFICATION_DATA',
  project_error_log_data: 'PROJECT_ERROR_LOG_DATA',
  project_response_data: 'PROJECT_RESPONSE_DATA',
  landingPages: 'LANDING_PAGES',
  landingPageLogs: 'LANDING_PAGE_LOGS',
  landing_page_export_data: 'LANDING_PAGE_EXPORT_DATA',
  venderConfigData: 'VENDOR_DATA',
  //blacklist_data: 'BLACKLIST_DATA',
};
