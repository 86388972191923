import Type from './types';

let initialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  propertyDetail: {},
};

let notificationInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  notificationDetail: {},
};

let bevakaStatsInitialState = {
  average_property_sent: 0,
  bevaka_created: 0,
  bevaka_created_by_rule: { rule1: 0, rule2: 0, rule3: 0, rule4: 0 },
  bevaka_created_by_vendor: { vitec: 0, fasad: 0, maklarhuset: 0, mspecs: 0 },
  bevaka_processed: 0,
  mail_sent: 0,
  mail_sent_by_by_rule: { rule1: 0, rule2: 0, rule3: 0, rule4: 0 },
  mail_sent_by_vendor: { vitec: 0, fasad: 0, maklarhuset: 0, mspecs: 0 },
  total_properties_not_sent_by_area: { '0-100': 0, '100-200': 0, '200-400': 0, '400+': 0 },
  total_properties_not_sent_by_price: {
    '0-5000000': 0,
    '5000000-10000000': 0,
    '10000000-15000000': 0,
    '15000000+': 0,
  },
  total_properties_not_sent_by_room: { '0-3': 0, '3-5': 0, '5-8': 0, '8+': 0 },
  total_properties_sent: 0,
  viewing_passed: 0,
  _id: null,
};
let byBrandsInitialState = {
  average_property_sent: null,
  bevaka_created: 0,
  bevaka_processed: 0,
  mail_sent: 0,
  total_properties_sent: 0,
  viewing_passed: 0,
  _id: null,
};
let byAgencyInitialState = {
  average_property_sent: null,
  bevaka_created: 0,
  bevaka_processed: 0,
  mail_sent: 0,
  total_properties_sent: 0,
  viewing_passed: 0,
  _id: null,
};
let byAgentInitialState = {
  average_property_sent: null,
  bevaka_created: 0,
  bevaka_processed: 0,
  mail_sent: 0,
  total_properties_sent: 0,
  viewing_passed: 0,
  _id: null,
};
let byLocationInitialState = {
  average_property_sent: null,
  bevaka_created: 0,
  bevaka_processed: 0,
  mail_sent: 0,
  total_properties_sent: 0,
  viewing_passed: 0,
  _id: null,
};

let jamforInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  jamforMaklareDetail: {},
};

let leadsInvoiceInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  jamforMaklareDetail: {},
};

let errorlogInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  errorDetail: {},
};

let projectInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  projectDetail: {},
};

let projectErrorlogInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  errorDetail: {},
};

let projectNotificationInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  notificationDetail: {},
};

let bevakaInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  bevakaDetail: {},
};

let bevakaMailInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  bevakaMailLogDetail: {},
};

let propertyInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  propertyDetail: {},
  propertyAdsDetail: {},
};

let advertisementInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  advertisementDetail: {},
};

let agencyInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  agencyDetail: {},
};

let dashboardInitialState = {
  errordata: 0,
  notificationdata: 0,
  property_data: 0,
  property_export_data: 0,
  errorChart: {},
  notificationChart: {},
  property_ids: [],
  vendor_property_id: '',
  propertyChart: {},
};

let agentInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  agentDetail: {},
  agentPropertyCount: {},
};

let autocompleterInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  autocompleterDetail: {},
};

let brandInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  brandDetail: {},
};

let userInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  userDetail: {},
  status: {},
};

let roleInitialState = {
  docs: [],
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  roleDetail: {},
  roleDropDownList: [],
  status: {},
};

let projectsInitialState = {
  docs: [],
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  projectsDetail: {},
  mappedPropertyList: [],
};

let constructorInitialState = {
  docs: [],
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  constructorDetail: {},
};

let articleInitialState = {
  docs: [],
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  articleDetail: {},
};

let inquiryInitialState = {
  docs: [],
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  inquiryDetail: {},
  projectList: [],
  constructorList: [],
};

let autocompleterOtherCountryInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  autocompleterDetail: {},
};
let advertisementLogInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  advertisementLogDetail: {},
};

let landingPagesInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  landingPageDetail: {},
};

let landingPageLogInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  landing_page_export_data: 0,
  landingPageLogDetail: {},
};

let packageInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  packageDetail: {},
};


/*let blacklistInitialState = {
  total: 0,
  limit: 0,
  page: 1,
  pages: 0,
  blacklistDetail: {},
};*/

export const propertyResponseReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.property_response_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const notificationReducer = (state = notificationInitialState, { type, payload }) => {
  switch (type) {
    case Type.notification_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const jamforMaklareReducer = (state = jamforInitialState, { type, payload }) => {
  switch (type) {
    case Type.jamfor_maklare_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const leadsInvoiceReducer = (state = leadsInvoiceInitialState, { type, payload }) => {
  switch (type) {
    case Type.leads_invoice_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const errorLogReducer = (state = errorlogInitialState, { type, payload }) => {
  switch (type) {
    case Type.error_log_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const projectResponseReducer = (state = projectInitialState, { type, payload }) => {
  switch (type) {
    case Type.project_response_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const projectNotificationReducer = (
  state = projectNotificationInitialState,
  { type, payload }
) => {
  switch (type) {
    case Type.project_notification_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const projectErrorLogReducer = (state = projectErrorlogInitialState, { type, payload }) => {
  switch (type) {
    case Type.project_error_log_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const bevakaMailLogsReducer = (state = bevakaMailInitialState, { type, payload }) => {
  switch (type) {
    case Type.bevaka_mail_logs_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const bevakaReducer = (state = bevakaInitialState, { type, payload }) => {
  switch (type) {
    case Type.bevaka_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const propertyRevisionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case Type.property_revision_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const propertyReducer = (state = propertyInitialState, { type, payload }) => {
  switch (type) {
    case Type.property_data:
      return { ...state, ...payload };
    case Type.property_export_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const advertisementReducer = (state = advertisementInitialState, { type, payload }) => {
  switch (type) {
    case Type.advertisement_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const advertisementLogReducer = (
  state = advertisementLogInitialState,
  { type, payload }
) => {
  switch (type) {
    case Type.advertisement_log_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const agencyReducer = (state = agencyInitialState, { type, payload }) => {
  switch (type) {
    case Type.agency_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const dashboardReducer = (state = dashboardInitialState, { type, payload }) => {
  switch (type) {
    case Type.dashboard_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const agentReducer = (state = agentInitialState, { type, payload }) => {
  switch (type) {
    case Type.agent_data:
      return { ...state, ...payload };
    case Type.agent_property_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const autocompleterReducer = (state = autocompleterInitialState, { type, payload }) => {
  switch (type) {
    case Type.autocompleter_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const brandReducer = (state = brandInitialState, { type, payload }) => {
  switch (type) {
    case Type.brand_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const userReducer = (state = userInitialState, { type, payload }) => {
  switch (type) {
    case Type.users_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const roleReducer = (state = roleInitialState, { type, payload }) => {
  switch (type) {
    case Type.roles_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const projectsReducer = (state = projectsInitialState, { type, payload }) => {
  switch (type) {
    case Type.projects_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const constructorReducer = (state = constructorInitialState, { type, payload }) => {
  switch (type) {
    case Type.constructor_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const articleReducer = (state = articleInitialState, { type, payload }) => {
  switch (type) {
    case Type.article_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const inquiryReducer = (state = inquiryInitialState, { type, payload }) => {
  switch (type) {
    case Type.inquiry_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const appNotificationReducer = (state = { type: '', message: '' }, { type, payload }) => {
  switch (type) {
    case Type.app_notification_message:
      return payload;
    default:
      return state;
  }
};

export const autocompleterOtherCountryReducer = (
  state = autocompleterOtherCountryInitialState,
  { type, payload }
) => {
  switch (type) {
    case Type.autocompleter_other_country_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const bevakaStatsReducer = (state = bevakaStatsInitialState, { type, payload }) => {
  switch (type) {
    case Type.bevaka_stats_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const byBrandsReducer = (state = byBrandsInitialState, { type, payload }) => {
  switch (type) {
    case Type.by_brands_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const byAgencyReducer = (state = byAgencyInitialState, { type, payload }) => {
  switch (type) {
    case Type.by_agency_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const byAgentReducer = (state = byAgentInitialState, { type, payload }) => {
  switch (type) {
    case Type.by_agent_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};
export const byLocationReducer = (state = byLocationInitialState, { type, payload }) => {
  switch (type) {
    case Type.by_location_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const brandFilterReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Type.brand_filter_data:
      return payload;
    default:
      return state;
  }
};
export const agenciesFilterReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Type.agency_filter_data:
      return payload;
    default:
      return state;
  }
};
export const agentFilterReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Type.agent_filter_data:
      return payload;
    default:
      return state;
  }
};
export const locationFilterReducer = (state = [], { type, payload }) => {
  switch (type) {
    case Type.location_filter_data:
      return payload;
    default:
      return state;
  }
};
export const isExportingReducer = (state = false, { type, payload }) => {
  switch (type) {
    case Type.is_exporting:
      return payload;
    default:
      return state;
  }
};
export const prevRequestReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case Type.prevRequest:
      return payload;
    default:
      return state;
  }
};

export const landingPagesReducer = (state = landingPagesInitialState, { type, payload }) => {
  switch (type) {
    case Type.landingPages:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const landingPagesLogReducer = (state = landingPageLogInitialState, { type, payload }) => {
  switch (type) {
    case Type.landingPageLogs:
      return { ...state, ...payload };
    case Type.landing_page_export_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const packageReducer = (state = packageInitialState, { type, payload }) => {
  switch (type) {
    case Type.package_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const venderConfigReducer = (state = null, { type, payload }) => {
  switch (type) {
    case Type.venderConfigData:
      return payload;
    default:
      return state;
  }
};

/*export const blacklistReducer = (state = blacklistInitialState, { type, payload }) => {
  switch (type) {
    case Type.blacklist_data:
      return { ...state, ...payload };
    default:
      return state;
  }
};*/
