import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import '../assets/css/App.scss';
import Authenticate from '../components/AuthenticateLogin';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const Login = React.lazy(() => import('../screens/Login'));
const Register = React.lazy(() => import('../screens/Register'));
const Page404 = React.lazy(() => import('../screens/Page404'));
const Page500 = React.lazy(() => import('../screens/Page500'));
const ForgotPassword = React.lazy(() => import('../screens/User/ForgotPassword/'));
const ForgotPasswordVerification = React.lazy(() =>
  import('../screens/User/ForgotPassword/PasswordVerification')
);
const ForceResetPassword = React.lazy(() => import('../screens/User/ForceResetPassword/'));
const ThankyouPage = React.lazy(() => import('../screens/User/ThankyouPage/'));

export default function Routes() {
  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route
            path="/forgotpassword"
            name="ForgotPassword"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            path="/forgotpasswordverification"
            name="ForgotPasswordVerification"
            render={(props) => <ForgotPasswordVerification {...props} />}
          />
          <Route
            path="/forceresetpassword"
            name="ForceResetPassword"
            render={(props) => <ForceResetPassword {...props} />}
          />
          <Route
            path="/thank-you"
            name="Thank You"
            render={(props) => <ThankyouPage {...props} />}
          />
          <Route path="/" name="Home" render={(props) => <Authenticate {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
}

const Dashboard = React.lazy(() => import('../screens/Dashboard'));
const PropertyResponse = React.lazy(() => import('../screens/PropertyResponse'));
const Packages = React.lazy(() => import('../screens/Packages'));
const Errors = React.lazy(() => import('../screens/Errors/errors'));
const Notifications = React.lazy(() => import('../screens/Notifications'));
const ProjectResponse = React.lazy(() => import('../screens/ProjectResponse'));
const ProjectErrors = React.lazy(() => import('../screens/ProjectErrors'));
const ProjectNotifications = React.lazy(() => import('../screens/ProjectNotifications'));
const PropertyRevision = React.lazy(() => import('../screens/PropertyRevision'));
// const Blacklist = React.lazy(() => import('../screens/Blacklist'));
// const AddBlacklist = React.lazy(() => import('../screens/Blacklist/Add'));

const Property = React.lazy(() => import('../screens/Properties'));
const EditProperties = React.lazy(() => import('../screens/Properties/Edit'));

const bevaka = React.lazy(() => import('../screens/Bevaka'));
const LeadsInvoice = React.lazy(() => import('../screens/LeadsInvoice'));
const RealtoBevaka = React.lazy(() => import('../screens/RealtoBevaka'));

const JamforeMaklare = React.lazy(() => import('../screens/JamforeMaklare'));
const EditJamforeMaklare = React.lazy(() => import('../screens/JamforeMaklare/Edit'));

const BevakaMailLogs = React.lazy(() => import('../screens/BevakaMailLogs'));
const Agent = React.lazy(() => import('../screens/Agent'));
const AddAgent = React.lazy(() => import('../screens/Agent/Add'));
const EditAgent = React.lazy(() => import('../screens/Agent/Edit'));
const ViewAgent = React.lazy(() => import('../screens/Agent/View'));
const Autocompleter = React.lazy(() => import('../screens/Autocompleter'));
const AddAutocompleter = React.lazy(() => import('../screens/Autocompleter/Add'));
const EditAutocompleter = React.lazy(() => import('../screens/Autocompleter/Edit'));
const User = React.lazy(() => import('../screens/User/index'));
const AddUser = React.lazy(() => import('../screens/User/Add'));
const EditUser = React.lazy(() => import('../screens/User/Edit'));
const ViewUser = React.lazy(() => import('../screens/User/View'));
const ResetPassword = React.lazy(() => import('../screens/User/ResetPassword/'));
const Agency = React.lazy(() => import('../screens/Agency'));
const ViewAgency = React.lazy(() => import('../screens/Agency/View'));
const EditAgency = React.lazy(() => import('../screens/Agency/Edit'));
const AddAgency = React.lazy(() => import('../screens/Agency/Add'));
const Roles = React.lazy(() => import('../screens/Roles'));
const AddRole = React.lazy(() => import('../screens/Roles/Add'));
const EditRole = React.lazy(() => import('../screens/Roles/Edit'));
const Brand = React.lazy(() => import('../screens/Brand'));
const AddBrand = React.lazy(() => import('../screens/Brand/Add'));
const EditBrand = React.lazy(() => import('../screens/Brand/Edit'));
const ViewBrand = React.lazy(() => import('../screens/Brand/View'));
const Projects = React.lazy(() => import('../screens/Projects'));
const ViewProjects = React.lazy(() => import('../screens/Projects/View'));
const AddProjects = React.lazy(() => import('../screens/Projects/Add'));
const EditProjects = React.lazy(() => import('../screens/Projects/Edit'));
const Constructor = React.lazy(() => import('../screens/Constructor'));
const AddConstructor = React.lazy(() => import('../screens/Constructor/Add'));
const EditConstructor = React.lazy(() => import('../screens/Constructor/Edit'));
const ViewConstructor = React.lazy(() => import('../screens/Constructor/View'));
const Inquiry = React.lazy(() => import('../screens/Inquiry'));
const AutocompleterOtherCountry = React.lazy(() => import('../screens/AutocompleterOtherCountry'));
const AddAutocompleterOtherCountry = React.lazy(() =>
  import('../screens/AutocompleterOtherCountry/Add')
);
const EditAutocompleterOtherCountry = React.lazy(() =>
  import('../screens/AutocompleterOtherCountry/Edit')
);
const BevakaStats = React.lazy(() => import('../screens/BevakaStats'));
const BevakaStatsByBrand = React.lazy(() => import('../screens/BevakaStatsByBrand'));
const BevakaStatsByAgency = React.lazy(() => import('../screens/BevakaStatsByAgency'));
const BevakaStatsByAgent = React.lazy(() => import('../screens/BevakaStatsByAgent'));
const BevakaStatsByLocation = React.lazy(() => import('../screens/BevakaStatsByLocation'));
// const BevakaStatsActiveUsers = React.lazy(() => import('../screens/BevakaStatsActiveUsers'));
// const BevakaStatsActiveBevaka = React.lazy(() => import('../screens/BevakaStatsActiveBevaka'));
// const BevakaStatsBevakaInteraction = React.lazy(() => import('../screens/BevakaStatsBevakaInteraction'));
const Indexing = React.lazy(() => import('../screens/Indexing'));
const Advertisement = React.lazy(() => import('../screens/Advertisement'));
const Logs = React.lazy(() => import('../screens/Logs'));
const LandingPages = React.lazy(() => import('../screens/LandingPages'));
const LandingPageLogs = React.lazy(() => import('../screens/LandingPageLogs'));
const VenderSettings = React.lazy(() => import('../screens/VenderSettings'));

export const subRoutes = [
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/propertyresponse', name: 'Property Responses', component: PropertyResponse },
  { path: '/packages', name: 'Packages', component: Packages },
  { path: '/errors', name: 'Error', component: Errors },
  { path: '/notifications', name: 'Notifications', component: Notifications },
  { path: '/propertyrevision', name: 'Property Revisions', component: PropertyRevision },
  { path: '/property/edit/:id', name: 'Edit', component: EditProperties },
  { path: '/property', exact: true, name: 'Properties', component: Property },
  { path: '/bevaka', name: 'Bevaka', component: bevaka },
  { path: '/leads-invoice', name: 'Leads Invoice', component: LeadsInvoice },
  { path: '/realtor-bevaka', name: 'Bevaka Leads', component: RealtoBevaka },
  { path: '/seller-leads', exact: true, name: 'Seller Leads', component: JamforeMaklare },
  { path: '/seller-leads/edit/:id', name: 'Edit', component: EditJamforeMaklare },
  { path: '/bevakamaillogs', name: 'Bevaka Mail Logs', component: BevakaMailLogs },
  { path: '/agent', exact: true, name: 'Agents', component: Agent },
  { path: '/agent/edit/:id', name: 'Edit', component: EditAgent },
  { path: '/agent/view/:id', name: 'View', component: ViewAgent },
  { path: '/agent/add', name: 'Add', component: AddAgent },
  { path: '/places', exact: true, name: 'Places', component: Autocompleter },
  { path: '/places/add', name: 'Add', component: AddAutocompleter },
  { path: '/places/edit/:id', name: 'Edit', component: EditAutocompleter },
  { path: '/users', exact: true, name: 'User', component: User },
  { path: '/users/edit/:id', name: 'Edit', component: EditUser },
  { path: '/users/add', name: 'Add', component: AddUser },
  { path: '/users/view/:id', name: 'View', component: ViewUser },
  { path: '/resetpassword', name: 'ResetPassword', component: ResetPassword },
  { path: '/agency/add', name: 'Add', component: AddAgency },
  { path: '/agency/edit/:id', name: 'Edit', component: EditAgency },
  { path: '/agency/view/:id', name: 'View', component: ViewAgency },
  { path: '/agency', exact: true, name: 'Agencies', component: Agency },
  { path: '/roles', exact: true, name: 'Roles', component: Roles },
  { path: '/roles/add', name: 'Add', component: AddRole },
  { path: '/roles/edit/:id', name: 'Edit', component: EditRole },
  { path: '/brands/add', name: 'Add', component: AddBrand },
  { path: '/brands/edit/:id', name: 'Edit', component: EditBrand },
  { path: '/brands/view/:id', name: 'View', component: ViewBrand },
  { path: '/brands', exact: true, name: 'Brands', component: Brand },
  { path: '/projects', exact: true, name: 'Projects', component: Projects },
  { path: '/projects/view/:id', name: 'View', component: ViewProjects },
  { path: '/projects/add', name: 'Add', component: AddProjects },
  { path: '/projects/edit/:id', name: 'Edit', component: EditProjects },
  { path: '/constructor', exact: true, name: 'Construction Company', component: Constructor },
  { path: '/constructor/edit/:id', name: 'Edit', component: EditConstructor },
  { path: '/constructor/add', name: 'Add', component: AddConstructor },
  { path: '/constructor/view/:id', name: 'View', component: ViewConstructor },
  { path: '/inquiry', exact: true, name: 'Inquiry', component: Inquiry },
  { path: '/advertisement', name: 'Social Ads', component: Advertisement },
  {
    path: '/places-utland',
    exact: true,
    name: 'Utland Places',
    component: AutocompleterOtherCountry,
  },
  { path: '/places-utland/add', name: 'Add', component: AddAutocompleterOtherCountry },
  { path: '/places-utland/edit/:id', name: 'Edit', component: EditAutocompleterOtherCountry },
  { path: '/bevaka-stats', name: 'Bevaka Stats', component: BevakaStats },
  { path: '/bevaka-stats-by-brand', name: 'By Brand', component: BevakaStatsByBrand },
  {
    path: '/bevaka-stats-by-agency',
    name: 'By Agency',
    component: BevakaStatsByAgency,
  },
  { path: '/bevaka-stats-by-agent', name: 'By Agent', component: BevakaStatsByAgent },
  {
    path: '/bevaka-stats-by-location',
    name: 'By Location',
    component: BevakaStatsByLocation,
  },
  // {
  //   path: '/bevaka-stats-active-users',
  //   name: 'Active Users',
  //   component: BevakaStatsActiveUsers,
  // },
  // {
  //   path: '/bevaka-stats-active-bevaka',
  //   name: 'Active Bevaka',
  //   component: BevakaStatsActiveBevaka,
  // },
  // {
  //   path: '/bevaka-stats-bevaka-interaction',
  //   name: 'Bevaka Interaction',
  //   component: BevakaStatsBevakaInteraction,
  // },
  { path: '/indexing', exact: true, name: 'Indexing', component: Indexing },
  { path: '/logs', exact: true, name: 'Social Ads Logs', component: Logs },
  { path: '/project-response', name: 'Project Responses', component: ProjectResponse },
  { path: '/project-errors', name: 'Project Error', component: ProjectErrors },
  {
    path: '/project-notifications',
    name: 'Project Notifications',
    component: ProjectNotifications,
  },
  { path: '/landing-pages', name: 'Landing Pages', component: LandingPages },
  {
    path: '/landing-pages-logs',
    name: 'Landing Page Logs',
    component: LandingPageLogs,
  },
  { path: '/vendor-settings', name: 'Vendor Settings', component: VenderSettings },
  // { path: '/blacklist', name: 'Blacklist', exact: true, component: Blacklist },
  // { path: '/blacklist/add', name: 'Add', component: AddBlacklist },
];

// sidebar nav config
export const navigation = {
  items: [
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'icon-speedometer',
      badge: {
        variant: 'info',
        // text: 'NEW',
      },
      role: ['boneo_brand_admin', 'boneo_super_admin'],
    },
    {
      name: 'Property Logs',
      icon: 'fa fa-heartbeat',
      children: [
        {
          name: 'Notifications',
          url: '/notifications',
          icon: 'fa fa-bell',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
        {
          name: 'Errors',
          url: '/errors',
          icon: 'fa fa-warning',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
        {
          name: 'Property Response',
          url: '/propertyresponse',
          icon: 'fa fa-align-justify',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Project Logs',
      icon: 'fa fa-heartbeat',
      children: [
        {
          name: 'Notifications',
          url: '/project-notifications',
          icon: 'fa fa-bell',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
        {
          name: 'Errors',
          url: '/project-errors',
          icon: 'fa fa-warning',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
        {
          name: 'Project Response',
          url: '/project-response',
          icon: 'fa fa-align-justify',
          badge: {
            variant: 'info',
            // text: 'NEW',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Property',
      icon: 'fa fa-building',
      children: [
        {
          url: '/property',
          name: 'Properties',
          icon: 'fa fa-building-o',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/propertyrevision',
          icon: 'fa fa-building',
          name: 'Property Revision',
          badge: {
            variant: 'info',
          },
        },
        /*{
          url: '/blacklist',
          icon: 'fa fa-building',
          name: 'Blacklist',
          badge: {
            variant: 'info',
          },
        },*/
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Bevaka',
      icon: 'fa fa-btc',
      children: [
        {
          url: '/bevakamaillogs',
          icon: 'fa fa-envelope-o',
          name: 'Bevaka Mail Logs',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/bevaka',
          icon: 'fa fa-table',
          name: 'Bevaka',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Bevaka Stats',
          icon: 'fa fa-table',
          children: [
            {
              url: '/bevaka-stats',
              icon: 'fa fa-tv',
              name: 'Bevaka Stats',
              badge: {
                variant: 'info',
              },
            },
            {
              url: '/bevaka-stats-by-brand',
              icon: 'fa fa-building',
              name: 'By Brand',
              badge: {
                variant: 'info',
              },
            },
            {
              url: '/bevaka-stats-by-agency',
              icon: 'fa fa-building',
              name: 'By Agency',
              badge: {
                variant: 'info',
              },
            },
            {
              url: '/bevaka-stats-by-agent',
              icon: 'fa fa-user-circle-o',
              name: 'By Agent',
              badge: {
                variant: 'info',
              },
            },
            {
              url: '/bevaka-stats-by-location',
              icon: 'fa fa-map-marker',
              name: 'By Location',
              badge: {
                variant: 'info',
              },
            },
            // {
            //   url: '/bevaka-stats-active-users',
            //   icon: 'fa fa-user',
            //   name: 'Active Users',
            //   badge: {
            //     variant: 'info',
            //   },
            // },
            // {
            //   url: '/bevaka-stats-active-bevaka',
            //   icon: 'fa fa-envelope-open-o',
            //   name: 'Active Bevaka',
            //   badge: {
            //     variant: 'info',
            //   },
            // },
            // {
            //   url: '/bevaka-stats-bevaka-interaction',
            //   icon: 'fa fa-history',
            //   name: 'Bevaka Interaction',
            //   badge: {
            //     variant: 'info',
            //   },
            // },
          ],
          role: ['boneo_brand_admin', 'boneo_super_admin'],
        },
      ],
      role: ['boneo_brand_admin', 'boneo_super_admin'],
    },
    {
      name: 'Manage Brand',
      icon: 'fa fa-building',
      children: [
        {
          url: '/brands',
          icon: 'fa fa-building',
          name: 'Brands',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/agency',
          icon: 'fa fa-building',
          name: 'Agencies',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/agent',
          icon: 'fa fa-user-circle-o',
          name: 'Agents',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Leads',
      icon: 'fa fa-table',
      children: [
        {
          url: '/leads-invoice',
          icon: 'fa fa-table',
          name: 'Leads Invoice',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/realtor-bevaka',
          icon: 'fa fa-table',
          name: 'Bevaka Leads',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/seller-leads',
          icon: 'fa fa-users',
          name: 'Seller Leads',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Seller Leads',
      url: '/seller-leads',
      icon: 'fa fa-users',
      badge: {
        variant: 'info',
      },
      role: ['boneo_verify_lead'],
    },
    {
      name: 'Place',
      icon: 'fa fa-map-marker',
      children: [
        {
          url: '/places',
          icon: 'fa fa-map-marker',
          name: 'Sweden Places',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/places-utland',
          icon: 'fa fa-map-marker',
          name: 'Utland Places',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'User',
      icon: 'fa fa-users',
      children: [
        {
          url: '/users',
          icon: 'fa fa-user',
          name: 'Users List',
          badge: {
            variant: 'info',
          },
        },
        {
          url: '/roles',
          icon: 'fa fa-tags',
          name: 'Roles List',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Project',
      icon: 'fa fa-building',
      children: [
        {
          name: 'Projects List',
          url: '/projects',
          icon: 'fa fa-building',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Inquiry',
          url: '/inquiry',
          icon: 'fa fa-envelope-o',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin', 'boneo_constructor'],
    },
    {
      url: '/constructor',
      icon: 'fa fa-users',
      name: 'Construction Company',
      badge: {
        variant: 'info',
      },
      role: ['boneo_super_admin'],
    },
    {
      url: '/indexing',
      icon: 'fa fa-users',
      name: 'Indexing',
      badge: {
        variant: 'info',
      },
      role: ['boneo_super_admin'],
    },
    {
      url: '/packages',
      icon: 'fa fa-money',
      name: 'Package / Invoice',
      badge: {
        variant: 'info',
      },
      role: ['boneo_super_admin'],
    },
    {
      name: 'Social Ad',
      icon: 'fa fa-film',
      children: [
        {
          name: 'Social Ads',
          url: '/advertisement',
          icon: 'fa fa-picture-o',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Logs',
          url: '/logs',
          icon: 'fa fa-tasks',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      name: 'Landing Page',
      icon: 'fa fa-building',
      children: [
        {
          name: 'Page List',
          url: '/landing-pages',
          icon: 'fa fa-building',
          badge: {
            variant: 'info',
          },
        },
        {
          name: 'Logs',
          url: '/landing-pages-logs',
          icon: 'fa fa-tasks',
          badge: {
            variant: 'info',
          },
        },
      ],
      role: ['boneo_super_admin'],
    },
    {
      url: '/vendor-settings',
      icon: 'fa fa-cog',
      name: 'Vendor Settings',
      badge: {
        variant: 'info',
      },
      role: ['boneo_super_admin'],
    },
  ],
};
