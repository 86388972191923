import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../configuration/authentication';
import { subRoutes } from '../configuration/routes';
import { ROLE_BASE_SCREEN_ACCESS } from '../constants';
import { navigation } from '../../src/configuration/routes';

const DefaultLayout = React.lazy(() => import('../components/DefaultLayout'));

export default function AuthenticateLogin(props) {
  const {
    history: {
      location: { pathname = '' },
      replace,
    },
  } = props;
  const [isLogin, setIsLogin] = useState(false);
  const [checking, setChecking] = useState(true);
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        setStateVariable(null, true);
        let isAuthenticate = await Auth.pageAuthentication();
        /** User is Authenticated */
        if (isAuthenticate) {
          /** No route is spacified so redirect to 1st screen*/
          if (pathname === '/') {
            let role = await Auth.getCurrentUserRole();
            if (role.length > 0) {
              role.map((r) => {
                if (ROLE_BASE_SCREEN_ACCESS[r] !== undefined) {
                  let pageToRedirect = ROLE_BASE_SCREEN_ACCESS[r.trim()]['screen'];
                  setStateVariable(true, false);
                  replace(`/${pageToRedirect[0]}`);
                }
              });
            } else {
              setStateVariable(false, false);
            }
          } else {
            /** Validate Route */
            const isValid = await validateRoute();
            /** Route is not exists */
            if (!isValid) {
              replace('/404');
              setStateVariable(false, false);
            } else {
              setStateVariable(true, false);
            }
          }
        } else {
          Auth.logout();
          replace('/login');
          setStateVariable(false);
        }
      } catch (error) {
        replace('/login');
        setStateVariable(false, false);
      }
    };

    const setStateVariable = (login, checking) => {
      login !== undefined && login != null && setIsLogin(login);
      checking !== undefined && checking != null && setChecking(checking);
    };
    const validateRoute = () => {
      return new Promise(async (res) => {
        let routes = [];
        let role = await Auth.getCurrentUserRole();
        const subPath = pathname && pathname !== '' ? pathname.split('/') : null;
        if (role.length > 0 && pathname !== '') {
            navigation.items.map((pageDetail) => {
              role.map((r) => {
                if (pageDetail.role.includes(r) || pageDetail.role.includes('*')) {
                  routes  = updateChildRoute(pageDetail,routes)
                }
              });
            });
        }

        if (routes.length > 0 && subPath !== null && routes.includes(subPath[1])) {
          return res(true);
        }
        return res(false);
      });
    };
    checkAuthentication();
    return () => {
      setChecking(true);
      setIsLogin(false);
    };
  }, [subRoutes, replace, pathname, Auth]);
  if (checking || isLogin) {
    return <DefaultLayout {...props} ischecking={checking} />;
  }
  return <Redirect from="/" to="login" />;
}

function updateChildRoute(pageDetail, routes) {
  if (pageDetail.url) {
    routes.push(pageDetail.url.split('/')[1]);
  } else {
    pageDetail.children.map((page) => {
      updateChildRoute(page, routes);
    });
  }
  return routes;
}
