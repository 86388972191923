import { combineReducers } from 'redux';
import { createReducer, setData } from './handlers';
import {
  propertyResponseReducer,
  bevakaReducer,
  bevakaMailLogsReducer,
  dashboardReducer,
  agentReducer,
  agencyReducer,
  errorLogReducer,
  jamforMaklareReducer,
  leadsInvoiceReducer,
  propertyReducer,
  propertyRevisionReducer,
  notificationReducer,
  autocompleterReducer,
  userReducer,
  roleReducer,
  brandReducer,
  projectsReducer,
  constructorReducer as constructors,
  inquiryReducer,
  appNotificationReducer,
  autocompleterOtherCountryReducer,
  bevakaStatsReducer,
  byBrandsReducer,
  byAgencyReducer,
  byAgentReducer,
  byLocationReducer,
  brandFilterReducer,
  agenciesFilterReducer,
  agentFilterReducer,
  locationFilterReducer,
  isExportingReducer,
  prevRequestReducer,
  advertisementReducer,
  advertisementLogReducer,
  projectResponseReducer,
  projectNotificationReducer,
  projectErrorLogReducer,
  landingPagesReducer,
  landingPagesLogReducer,
  articleReducer,
  packageReducer,
  venderConfigReducer,
 // blacklistReducer
} from './reducer';
import type from './types';

export default combineReducers({
  propertyResponse: propertyResponseReducer,
  notification: notificationReducer,
  errorLog: errorLogReducer,
  projectResponse: projectResponseReducer,
  projectNotification: projectNotificationReducer,
  projectErrorLog: projectErrorLogReducer,
  dashboard: dashboardReducer,
  propertyRevision: propertyRevisionReducer,
  property: propertyReducer,
  jamforMaklare: jamforMaklareReducer,
  leadsInvoice: leadsInvoiceReducer,
  bevaka: bevakaReducer,
  agent: agentReducer,
  autocompleter: autocompleterReducer,
  bevaka_mail_logs: bevakaMailLogsReducer,
  isLoading: createReducer(true, { [type.is_loading]: setData }),
  isExporting: isExportingReducer,
  prevRequest: prevRequestReducer,
  isLogin: createReducer(false, { [type.is_login]: setData }),
  auth: createReducer({}, { [type.user_detail]: setData }),
  errorMessage: createReducer('', { [type.error_message_on_failure]: setData }),
  agency: agencyReducer,
  user: userReducer,
  role: roleReducer,
  brand: brandReducer,
  projects: projectsReducer,
  constructors,
  inquiry: inquiryReducer,
  appnotification: appNotificationReducer,
  autocompleterOtherCountry: autocompleterOtherCountryReducer,
  bevakaStats: bevakaStatsReducer,
  byBrands: byBrandsReducer,
  byAgency: byAgencyReducer,
  byAgent: byAgentReducer,
  byLocation: byLocationReducer,
  brandsFilter: brandFilterReducer,
  agenciesFilter: agenciesFilterReducer,
  agentFilter: agentFilterReducer,
  locationFilter: locationFilterReducer,
  advertisement: advertisementReducer,
  advertisementlogs: advertisementLogReducer,
  landingPages: landingPagesReducer,
  landingPageLogs: landingPagesLogReducer,
  article: articleReducer,
  package: packageReducer,
  venderCofiguration: venderConfigReducer,
 // blacklist: blacklistReducer,
});
