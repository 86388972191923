import React, { Component } from 'react';
import Routes from './routes';
import { connect } from 'react-redux';
import { configureCognito } from '../configuration/AmplifyObject';

const App = () => {
  configureCognito();
  return <Routes />;
};
function mapStateToProps({ auth }) {
  return { auth };
}
export default connect(mapStateToProps, null)(App);
