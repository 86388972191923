import * as AWSCognito from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import moment from 'moment';

let userPool;
let cognitoUser = null;

/**
 *  AuthStore is Class that provide you to Login, Signup, Forgot password, Reset Password Functionality.
 *  It is Wrapper on 'amazon-cognito-identity-js' Library.
 *  How to use :
 *    1) Import class
 *    3) call method ex. login
 */
class AuthStore {
  constructor() {
    AWS.config.region = process.env.REACT_APP_AWS_REGION; // Region
    const conf = {
      UserPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    };
    userPool = new AWSCognito.CognitoUserPool(conf);
  }
  errors = undefined;
  currentUser = null;

  /** Fprmate Error */
  simpleErr(err) {
    return {
      statusCode: err.statusCode,
      code: err.code,
      message: err.message,
    };
  }

  getUserDetail() {
    let userDetail = localStorage.getItem('USER-DETAIL');
    if (userDetail) {
      return JSON.parse(userDetail);
    }
    return null;
  }

  /** --- ACTIONS PERFORM WITH AWS-COGNITO ---- */

  /** 1) Login Method
   *  required values :
   *   Email : this.values.email
   *   Password : this.values.password
   */

  userAttributes = '';

  checkAWSCredentialExpired = () => {
    try {
      let creds = localStorage.getItem('AWS-CREDS');
      if (creds) {
        creds = JSON.parse(creds);
        const {
          Credentials: { Expiration },
        } = creds;
        let currentDate = moment().utc().format();
        let expiredDate = Expiration.toString();
        if (moment(expiredDate).isAfter(currentDate)) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.log('err in checkAWSCredentialExpired', error);
    }
  };
  getCognitoUserTokens = (sess) => {
    try {
      const tokens = {
        accessToken: sess.getAccessToken().getJwtToken(),
        idToken: sess.getIdToken().getJwtToken(),
        refreshToken: sess.getRefreshToken().getToken(),
      };
      return {
        tokens,
        session: sess,
      };
    } catch (error) {
      return error;
    }
  };
  getAWSCredential = (idToken) => {
    return new Promise((res, rej) => {
      try {
        let checkCredsExpiry = this.checkAWSCredentialExpired();
        if (checkCredsExpiry) {
          let creds = localStorage.getItem('AWS-CREDS');
          creds = JSON.parse(creds);
          res(creds);
        } else {
          const key = `cognito-idp.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID}`;
          AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_ID,
            Logins: {
              [key]: idToken,
            },
          });
          AWS.config.credentials.get((error) => {
            if (error) {
              console.error('some issue while getting credential', error);
              rej(error);
            } else {
              // add this timeout, because sometime we got AWS.config.credentials.data.Credentials null
              setTimeout(() => {
                localStorage.setItem('AWS-CREDS', JSON.stringify(AWS.config.credentials.data));
                res(AWS.config.credentials.data);
              }, 2000);
            }
          });
        }
      } catch (error) {
        rej(error);
      }
    });
  };
  login = (email, password) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    let authenticationDetails = new AWSCognito.AuthenticationDetails(authenticationData);
    const userData = { Username: email, Pool: userPool };
    cognitoUser = new AWSCognito.CognitoUser(userData);
    return new Promise((res, rej) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {
          try {
            localStorage.setItem('USER-DETAIL', JSON.stringify(session.getIdToken().payload));
            let identityToken = this.getCognitoUserTokens(session);
            let creds = await this.getAWSCredential(identityToken.tokens.idToken);
            res(creds);
          } catch (error) {
            rej(error);
          }
        },
        onFailure: (err) => rej(err),
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          this.userAttributes = userAttributes;
          return res({ ...userAttributes, newPasswordRequired: true });
        },
      });
    });
  };
  getCredentialForSignAPI = () => {
    return new Promise((res, rej) => {
      if (userPool && userPool.getCurrentUser()) {
        cognitoUser = userPool.getCurrentUser();
        try {
          cognitoUser.getSession(async (err, session) => {
            if (err) {
              this.logout(err);
              rej(err);
            } else {
              let creds = await this.getAWSCredential(session.getIdToken().getJwtToken());
              res(creds);
            }
          });
        } catch (err) {
          this.logout(err);
          rej(err);
        }
      }
    });
  };
  /** Logout Method */
  logout(error = null) {
    console.log(error);
    return new Promise(async (res) => {
      /** Remove Custom Data from LS */
      Object.keys(localStorage).forEach((key) => localStorage.removeItem(key));
      res();
    });
  }

  pageAuthentication = () => {
    try {
      return new Promise(async (res, rej) => {
        let detail = this.getUserDetail();
        if (detail) {
          let authenticateUser = await this.getCredentialForSignAPI();
          if (authenticateUser) {
            return res(true);
          } else {
            return res(false);
          }
        }
        return res(false);
      });
    } catch (error) {
      return new Promise(async (res, rej) => rej(false));
    }
  };

  getCurrentUserRole = () => {
    try {
      return new Promise(async (res, rej) => {
        let userDetail = this.getUserDetail();
        if (userDetail) {
          let roles = userDetail['cognito:roles'];
          let userRole = [];
          if (roles.length > 0) {
            roles.map((r) => {
              userRole.push(r.split('/')[1]);
            });
          }

          if (userRole) {
            return res(userRole);
          } else {
            return res(null);
          }
        }
        return res(null);
      });
    } catch (error) {
      return new Promise(async (res, rej) => rej(false));
    }
  };

  /** 2) Force Reset Password
   * For users which are required to change their passwords after successful first login
   * challenge : NEW_PASSWORD_REQUIRED
   *  required values :
   *    NewPassword : this.newPassword
   *    Attribut : this.values.other    ( new user attributes to be updated )
   */

  async forceResetPassword(newPassword) {
    try {
      if (userPool) {
        const result = await Auth.completeNewPassword(cognitoUser, newPassword);
        return result;
      }
    } catch (err) {
      console.log('err', err);
      this.errors = this.simpleErr(err);
      return new Promise((res, rej) => rej(this.errors));
    }
    // return new Promise((res, rej) => {
    //   cognitoUser.completeNewPasswordChallenge(newPassword, this.userAttributes, {
    //     onSuccess: (data) => {
    //       console.log('onSuccess data', data);
    //       this.userAttributes = '';
    //       return res(data);
    //     },
    //     onFailure: (error) => {
    //       console.log('onFailure error', error);
    //       this.errors = this.simpleErr(error);
    //       return rej(this.errors);
    //     },
    //   });
    // }).catch((err) => {
    //   console.log('err', err);
    //   this.errors = this.simpleErr(err);
    //   return new Promise((res, rej) => rej(this.errors));
    // });
  }

  /** 3) Change Password Method
   *  required values :
   *     OldPassword : this.oldPassword
   *     NewPassword : this.newPassword
   *  Note:
   *      -  User Login is required
   */

  changePassword(oldPassword, newPassword) {
    if (userPool && userPool.getCurrentUser()) {
      cognitoUser = userPool.getCurrentUser();
      return new Promise((res, rej) => {
        cognitoUser.getSession(async (err, session) => {
          if (err) {
            this.logout(err);
          } else {
            cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
              if (err) {
                this.errors = this.simpleErr(err);
                return rej(this.errors);
              } else if (result === 'SUCCESS') {
                return res(result);
              } else {
                return rej(result);
              }
            });
          }
        });
      }).catch((err) => {
        return new Promise((res, rej) => rej(err));
      });
    } else {
      return new Promise((res, rej) => rej('Please try again later'));
    }
  }

  /** 4) Forgot Password method
   *  required values :
   *    Email : this.values.email
   */

  forgotPassword(email) {
    if (userPool) {
      const userData = { Username: email, Pool: userPool };
      cognitoUser = new AWSCognito.CognitoUser(userData);
      return new Promise((res, rej) => {
        cognitoUser.forgotPassword({
          onSuccess: (data) => {
            return res(data);
          },
          onFailure: (error) => {
            this.errors = this.simpleErr(error);
            return rej(this.errors);
          },
          inputVerificationCode: (verifyCode) => {
            return res(verifyCode);
          },
        });
      });
    }
  }

  /** 5) Verify Code method
   *  required values :
   *    Email : this.values.email
   *    ConfirmCode : this.confirmCode ( you get it on Email )
   *    NewPassword : this.newPassword
   */

  confirmPassword(email, confirmCode, newPassword) {
    if (userPool) {
      const userData = { Username: email, Pool: userPool };
      cognitoUser = new AWSCognito.CognitoUser(userData);
      return new Promise((res, rej) => {
        cognitoUser.confirmPassword(confirmCode, newPassword, {
          onSuccess: (data) => {
            return res(data);
          },
          onFailure: (error) => {
            this.errors = this.simpleErr(error);
            return rej(this.errors);
          },
        });
      });
    }
  }
}
export default new AuthStore();
