import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";
const extensionCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

function store(params) {
    let store = createStore(
        rootReducer,
        // {
        //   init: 1
        // },
        extensionCompose(applyMiddleware(thunk))
    );
    return store;
}

export default store;
